import React from "react";
import "../Newest/Newest.scss";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import NewestItem from "./NewestItem/NewestItem";
import img from "../../../../../assets/images/newest.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import wave from "../../../../../assets/images/waves/najcitanejsie-top.svg";
import Translate from "react-translate-component";
import axios from "axios";

class Newest extends React.Component {
  _isMounted = false;

  state = {
    blogs: []
  };

  componentDidMount() {
    this._isMounted = true;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/rest/blogs`)
      .then(res => {
        if (this._isMounted) {
          const blogs = res.data.items;
          this.setState({ blogs });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <section className="Newest">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5 pt-5">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <SectionHeadline className="dark small left">
                  <Translate content="blog.news_headline" />
                </SectionHeadline>
              </ScrollAnimation>
            </div>

            {this.state.blogs.slice(0, 2).map(item => {
              return (
                <NewestItem
                  key={item.id}
                  id={item.id}
                  externalUrl={item.externalUrl}
                  headline={item.title}
                  text={item.description}
                  img={item.headingImageURL}
                  date={item.publishedAt}
                />
              );
            })}
          </div>
        </div>
        <img src={wave} alt="wave" className="cs_wave" />
      </section>
    );
  }
}

export default Newest;
