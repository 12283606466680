import styled from "styled-components";
import checked from "../../assets/images/checked.svg";

export const QFCheckboxInputSC = styled.input`
cursor:pointer;
  border-radius: 3px;
  border: solid 1px #DCD9D6;
  padding: 0;
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 2px;
  width: 24px;
  height: 24px;
  margin-right: 14px;
  
  ${props =>
    props.checked
      ? `background-image: url(${checked});background-color:#ff8b5f`
      : null}
  ${props => (props.hasError ? `color: #E33200;` : null)}
  ${props => (props.hasError ? `border-color: #E33200 !important;` : null)}
`;

export const CHSubmitBtnSC = styled.button`
  font-family: "Cabin", sans-serif;
  margin-top: 20px;
  font-size: 18px;
  max-width: 207px;
  width: 100%;
  padding: 10px 10px 9px 10px;
  border-radius: 22.5px;
  font-weight: 500;
  display: inline-block;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  color: #fff;
  background-image: linear-gradient(to top, #ff7554, #ffb070);

  @media screen and (max-width: 992px) {
    max-width: 270px;
    margin-top: 10px;
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.5) !important;
  }
`;
