import React from "react";

import CFForm from "./contact-form-form";
import { CFWrapperSC } from "./contact-form.styled";

const ContactForm = () => {
  return (
    <CFWrapperSC>
      <div className="cf-inner-wrapper">
        <CFForm />
      </div>
    </CFWrapperSC>
  );
};

export default ContactForm;
