import React from "react";
import "../../components/App/App.scss";
import counterpart from "counterpart";
import sk_lang from "../../lang/sk.json";
import Header from "../Header/Header";
import Homepage from "../Pages/Homepage/Homepage";
import About from "../Pages/About/About";
import Blog from "../Pages/Blog/Blog";
import Post from "../Pages/Blog/Post/Post";
import Contact from "../Pages/Contact/Contact";
import Questionaire from "../Pages/Question-form/Questionnaire";
import FormSent from "../Pages/FormSent/FormSent";
import Footer from "../Footer/Footer";
import NotFoundPage from "../Pages/not-found";
import { Route, Switch } from "react-router-dom";
import StateProvider from "../../context/state-context-provider";
import CookieConsent from "../cookie-consent/cookie-consent";
import Privacy from "../Pages/Privacy/Privacy";

counterpart.registerTranslations("sk", sk_lang);
counterpart.setFallbackLocale("sk");
counterpart.setLocale("sk");

class App extends React.Component {
  render() {
    return (
      <StateProvider>
        <div className="App">
          <Header />
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/about" component={About} />
            <Route path="/blog" component={Blog} />
            <Route path="/contact" component={Contact} />
            <Route path="/post/:id" component={Post} />
            <Route path="/question-form" component={Questionaire} />
            <Route path="/form-sent" component={FormSent} />
            <Route path="/privacy" component={Privacy} />
            <Route component={NotFoundPage} />
          </Switch>
          <CookieConsent />
          <Footer />
        </div>
      </StateProvider>
    );
  }
}

export default App;
