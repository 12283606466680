import React from "react";
import "../NewsItem/NewsItem.scss";
import ScrollAnimation from "react-animate-on-scroll";
import TextEllipsis from "react-text-ellipsis";
import Translate from "react-translate-component";

const NewsItem = props => {
  return (
    <div className="col-md-6 col-xl-4 mt-5">
      <a href={props.externalUrl} target="_blank" rel="noopener noreferrer">
        <ScrollAnimation
          animateIn="fadeIn"
          animateOnce={true}
          delay={props.delay}
        >
          <div className="NewsItem">
            <div className="cs_imgwrap">
              <img src={props.img} alt="item-pic" />
            </div>
            <div className="cs_contentwrap">
              <div>
                <p className="cs_title">{props.headline}</p>

                <TextEllipsis
                  lines={4}
                  tag={"p"}
                  ellipsisChars={"..."}
                  tagClass={"cs_text"}
                >
                  {props.text}
                </TextEllipsis>
              </div>
              <small className="cs_date">
                <Translate content="post.published" /> {props.date}
              </small>
            </div>
          </div>
        </ScrollAnimation>
      </a>
    </div>
  );
};

export default NewsItem;
