import React from "react";
import imgbg from "../../../../../../assets/images/teamitem-bg.svg";
import linkedimg from "../../../../../../assets/images/linked-in.svg";
import "./TeamItem.scss";
import ScrollAnimation from "react-animate-on-scroll";

const TeamItem = props => {
  return (
    <div className="TeamItem col-md-6 col-xl-3 mt-4 text-center">
      <ScrollAnimation
        animateIn="fadeIn"
        animateOnce={true}
        delay={props.delay}
      >
        <div className="cs_imgwrap">
          <img src={imgbg} alt="icon-bg" />
          <img src={props.img} alt="profile-pic" />
        </div>
        <p className="cs_title">{props.headline}</p>
        <p className="cs_text">{props.text}</p>
        {props.linked && <a
          href={props.linked}
          target="_blank"
          rel="noopener noreferrer"
          className="cs_linked"
        >
          <img src={linkedimg} alt="linked-in" />
        </a>}
      </ScrollAnimation>
    </div>
  );
};

export default TeamItem;
