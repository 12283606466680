import * as Yup from "yup";

const emMinChars = 2;
const emMaxChars = 200;

export default Yup.object().shape({
  email: Yup.string()
    .required("Nezadali ste e-mail")
    .email("Zadali ste chybný email")
    .min(emMinChars, `Zadali ste chybný email ${emMinChars}`)
    .max(
      emMaxChars,
      `Váš e-mail je príliš dlhý. Maximálny počet charakterov je ${emMaxChars}`
    ),
  newslatter: Yup.boolean()
    .oneOf([true], "Súhlas s podmienkami je povinný")
    .required("Súhlas je povinný")
});
