import React from "react";
import SVG from 'react-inlinesvg';
import CSModal from "./Modal";

import SvgCheck from "../../../assets/images/check.svg";

const SucModal = props => {
  return (
    <CSModal show={props.show} onHide={props.onHide} className="success-modal">
      <SVG src={SvgCheck} />
      <p>Ďakujeme. Boli ste úspešne pridaný do newslettera.</p>
    </CSModal>
  );
};

export default SucModal;
