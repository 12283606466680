import React from "react";
import { NavLink } from "react-router-dom";
import Translate from "react-translate-component";
import "../Navigation/Navigation.scss";
import Button from "../UI/Button/Button";
import { HashLink as Link } from "react-router-hash-link";

export const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth",
  });
};

const onLoginClick = () => {
  window.open(`${process.env.REACT_APP_AUTH_URI}`);
};

const Navigation = (props) => {
  return (
    <nav>
      <div>
        <Link
          scroll={(el) => scrollWithOffset(el, 90)}
          to="/#aboutproduct"
          onClick={props.onClick}
        >
          <Translate content="header-nav.link1" />
        </Link>
        <NavLink
          to="/about"
          className="NavLink"
          activeClassName="active"
          onClick={props.onClick}
        >
          <Translate content="header-nav.link2" />
        </NavLink>
        <Link
          scroll={(el) => scrollWithOffset(el, 90)}
          to="/#news"
          onClick={props.onClick}
        >
          <Translate content="header-nav.link3" />
        </Link>
        <NavLink
          to="/blog"
          className="NavLink"
          activeClassName="active"
          onClick={props.onClick}
        >
          <Translate content="header-nav.link4" />
        </NavLink>

        <NavLink
          to="/contact"
          className="NavLink"
          activeClassName="active"
          onClick={props.onClick}
        >
          <Translate content="header-nav.link6" />
        </NavLink>
        <a
          href="https://admin.careseehome.sk"
          className="NavLink external"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Translate content="header-nav.link7" />
        </a>
      </div>

      <Button
        className={`${props.className} login-btn nav-btn`}
        onClick={() => onLoginClick()}
      >
        <Translate content="header-nav.login" />
      </Button>
      <NavLink
        to="/question-form"
        className="btn-questionnaire"
        // activeClassName="active"
      >
        <Button
          className={`orange-dark navigation ${props.btnQuestionnaire} nav-btn`}
          onClick={props.onClick}
        >
          Mám záujem
        </Button>
      </NavLink>
    </nav>
  );
};

export default Navigation;
