import { Formik } from "formik";
import React, { useContext } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Translate from "react-translate-component";
import { StateContext } from "../../context/state-context-provider";
import Api from "../../utils/api";
import NLErrorElement from "../newslatter-form/newslatter-form-error";
import SectionHeadline from "./../UI/SectionHeadline/SectionHeadline";
import CFErrorElement from "./contact-form-error";
import ContactFormSchema from "./contact-form-rules";

import {
  CFSectionSC,
  CFSubmitBtnSC,
  CFSuccessMessageSC,
  CFTextAreaSC,
  CFTextInputSC,
  CHCheckboxInputSC,
} from "./contact-form.styled";

const ContactForm = () => {
  const formInitialValues = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    message: "",
    terms: false,
    newslatter: false,
  };

  const { setCFormOpen, contactSent, setContactSent } =
    useContext(StateContext);

  const { executeRecaptcha } = useGoogleReCaptcha();

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={ContactFormSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        // setTimeout(() => {
        //   setFormValues(values);
        //   setCFormOpen(true);
        //   setSubmitting(false);
        //   resetForm({});
        //   // reset current values
        //   values = formInitialValues;
        // }, 400);

        const sendContactMessage = async () => {
          const recaptchaToken = await executeRecaptcha("contact_form");
          const result = await Api.sendContactForm({
            first_name: values.name,
            last_name: values.surname,
            email: values.email,
            phone: values.phone,
            message: values.message,
            marketing_approval: values.newslatter,
            _token: recaptchaToken,
          });

          setSubmitting(false);
          resetForm({});

          if (result.status === 200) {
            setContactSent(true);
            setCFormOpen(false);
          }
        };

        sendContactMessage();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const nameHasError = errors && errors.name && touched.name;
        const surNameHasError = errors && errors.surname && touched.surname;
        const emailHasError = errors && errors.email && touched.email;
        const phoneHasError = errors && errors.phone && touched.phone;
        const msgHasError = errors && errors.message && touched.message;
        const newslatterHasError =
          errors && errors.newslatter && touched.newslatter;
        const termsHasError = errors && errors.terms && touched.terms;

        return (
          <form onSubmit={handleSubmit}>
            <CFSectionSC>
              <SectionHeadline className="dark small left">
                <Translate content="contact.contact_us" />
              </SectionHeadline>
              <div className="row fill-content">
                <div className="col-12 col-md-6">
                  <p>
                    <Translate content="contact.form.name_place" />
                  </p>
                  <CFTextInputSC
                    type="text"
                    name="name"
                    filled={values.name}
                    hasError={nameHasError}
                    placeholder="Zadajte Vaše meno"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name || ""}
                  />
                  {nameHasError ? (
                    <CFErrorElement errMsg={errors.name} />
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  <p>
                    <Translate content="contact.form.surname_place" />
                  </p>
                  <CFTextInputSC
                    type="text"
                    name="surname"
                    filled={values.surname}
                    hasError={surNameHasError}
                    placeholder="Zadajte Vaše priezvisko"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.surname || ""}
                  />
                  {surNameHasError ? (
                    <CFErrorElement errMsg={errors.surname} />
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  <p>
                    <Translate content="contact.form.email_place" />
                  </p>
                  <CFTextInputSC
                    type="email"
                    name="email"
                    filled={values.email}
                    hasError={emailHasError}
                    placeholder="Zadajte Váš email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email || ""}
                  />
                  {emailHasError ? (
                    <CFErrorElement errMsg={errors.email} />
                  ) : null}
                </div>
                <div className="col-12 col-md-6">
                  <p>
                    <Translate content="contact.form.phone_place" />
                  </p>
                  <CFTextInputSC
                    type="text"
                    name="phone"
                    filled={values.phone}
                    hasError={phoneHasError}
                    placeholder="Zadajte Vaše telefónne číslo v medzinárodnom formáte"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone || ""}
                  />
                  {phoneHasError ? (
                    <CFErrorElement errMsg={errors.phone} />
                  ) : null}
                </div>
                <div className="col-md-12 mb-5">
                  <p>
                    <Translate content="contact.form.message_place" />
                  </p>
                  <CFTextAreaSC
                    name="message"
                    placeholder="Napíšte Vašu správu"
                    hasError={msgHasError}
                    filled={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message || ""}
                  />
                  {msgHasError ? (
                    <CFErrorElement errMsg={errors.message} />
                  ) : null}
                </div>
                <div className="col-12 d-flex">
                  <CHCheckboxInputSC
                    type="checkbox"
                    hasError={termsHasError}
                    onBlur={handleBlur}
                    name="terms"
                    value="terms & conditions"
                    onChange={handleChange}
                    checked={values.terms || false}
                  />

                  <Translate
                    component="p"
                    content="homepage.newsletter.consent_text"
                    unsafe
                    className="checkbox-text"
                  />

                  {termsHasError ? (
                    <NLErrorElement errMsg={errors.terms} />
                  ) : null}
                </div>
                <div className="col-12 d-flex mt-3">
                  <CHCheckboxInputSC
                    type="checkbox"
                    hasError={newslatterHasError}
                    onBlur={handleBlur}
                    name="newslatter"
                    value="newslatter & conditions"
                    onChange={handleChange}
                    checked={values.newslatter || false}
                  />
                  <Translate
                    component="p"
                    content="homepage.newsletter.small_text"
                    unsafe
                    className="checkbox-text"
                  />
                  {newslatterHasError ? (
                    <NLErrorElement errMsg={errors.newslatter} />
                  ) : null}
                </div>
                <div className="col-md-12">
                  <div className="submit-btn-wrapper">
                    {contactSent ? (
                      <CFSuccessMessageSC>
                        Ďakujeme Vaša správa bola úspešne odoslaná
                      </CFSuccessMessageSC>
                    ) : null}
                    <CFSubmitBtnSC
                      type="submit"
                      disabled={isSubmitting}
                      isSent={false}
                    >
                      Odoslať formulár
                    </CFSubmitBtnSC>
                  </div>
                </div>
              </div>
            </CFSectionSC>
          </form>
        );
      }}
    </Formik>
  );
};

const WrappedContactForm = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
    <ContactForm />
  </GoogleReCaptchaProvider>
);

export default WrappedContactForm;
