import axios from "axios";

class Api {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_BASE_URL;
    this.axios = axios.create({
      timeout: process.env.REACT_APP_AXIOS_TIMEOUT
    });
    this.lang = process.env.REACT_APP_DEFAULT_LANG;
  }

  /**
   * Get most frequented search terms
   */
  sendContactForm = (body = null) =>
    this.axios.post(`${this.baseURL}/rest/contact-message`, body);

  sendQuestionForm = (body = null) =>
    this.axios.post(`${this.baseURL}/rest/order-message`, body);
}

export default new Api();
