import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const PartnersItem = props => {
  return (
    <div className="PartnersItem col-md-6 col-lg-4 mt-5 mb-5 text-center">
      <ScrollAnimation
        animateIn="fadeIn"
        animateOnce={true}
        delay={props.delay}
      >
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          <img
            src={props.img}
            alt="partner-logo"
            onMouseOver={e => (e.currentTarget.src = props.hovered)}
            onMouseLeave={e => (e.currentTarget.src = props.img)}
          />
        </a>
      </ScrollAnimation>
    </div>
  );
};

export default PartnersItem;
