import React, { useState } from "react";
import "./Accordion.scss";
import Translate from "react-translate-component";

import Solution from "./FAQ";

function AccordionQuestions() {
  const [solutions, setSolutions] = useState([
    {
      question: (
        <Translate
          content="homepage.solution.accordion_title_1"
          unsafe={true}
        />
      ),
      answer: (
        <Translate content="homepage.solution.accordion_text_1" unsafe={true} />
      ),
      open: false
    },
    {
      question: (
        <Translate
          content="homepage.solution.accordion_title_2"
          unsafe={true}
        />
      ),
      answer: (
        <Translate content="homepage.solution.accordion_text_2" unsafe={true} />
      ),
      open: false
    },
    {
      question: (
        <Translate
          content="homepage.solution.accordion_title_3"
          unsafe={true}
        />
      ),
      answer: (
        <Translate content="homepage.solution.accordion_text_3" unsafe={true} />
      ),
      open: false
    }
  ]);

  const toggleFAQ = index => {
    setSolutions(
      solutions.map((solution, i) => {
        if (i === index) {
          solution.open = !solution.open;
        } else {
          solution.open = false;
        }

        return solution;
      })
    );
  };

  return (
    <div className="Accordion">
      <div className="solutions">
        {solutions.map((solution, i) => (
          <Solution solution={solution} index={i} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
}

export default AccordionQuestions;
