import React from "react";

function Solution({ solution, index, toggleFAQ }) {
  return (
    <div
      className={"solution " + (solution.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="solution-question">{solution.question}</div>
      <div className="solution-answer">{solution.answer}</div>
    </div>
  );
}

export default Solution;
