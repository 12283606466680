import React from "react";
import PropTypes from "prop-types";
import { NLErrorSC } from "./newslatter.styled";

const NewslatterFormError = props => {
  const { errMsg, errClass } = props;
  return (
    <NLErrorSC className={`${errClass} newslatter-err`}>
      <div className="tooltip-arrow-up" />
      <div className="tooltip-box">{errMsg}</div>
    </NLErrorSC>
  );
};

export default NewslatterFormError;

NewslatterFormError.propTypes = {
  isVisible: PropTypes.bool,
  errMsg: PropTypes.node.isRequired
};

NewslatterFormError.defaultProps = {
  isVisible: false
};
