import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../../../../assets/images/1.png";
import img2 from "../../../../../assets/images/2.png";
import img3 from "../../../../../assets/images/3.png";
import img4 from "../../../../../assets/images/4.png";
import bgslider from "../../../../../assets/images/okrasa-srdce.svg";
import "../../../../Pages/Homepage/Sections/BannerSection/BannerSection.scss";
import Translate from "react-translate-component";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ScrollAnimation from "react-animate-on-scroll";
import { Animated } from "react-animated-css";
import { FiChevronDown } from "react-icons/fi";
import Button from "./../../../../UI/Button/Button";

const BannerSection = props => {
  return (
    <section className="BannerSection" id={props.scrollAnchor}>
      <div className="container">
        <div className="row">
          <div className="order-2 col-sm-12 order-md-1 col-md-6 top-row">
            <Animated animationIn="fadeIn">
              <ScrollAnimation
                animateIn="fadeInLeft"
                animateOnce={true}
                delay={props.delay}
              >
                <Translate
                  content="homepage.banner.headline_first_part"
                  component="h1"
                  style={{ marginBottom: "0" }}
                />
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInRight"
                animateOnce={true}
                delay={props.delay}
              >
                <Translate
                  content="homepage.banner.headline_second_part"
                  component="h1"
                  style={{ paddingTop: "0" }}
                />
              </ScrollAnimation>
              <Translate content="homepage.banner.text" component="p" unsafe />
            </Animated>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              className="d-flex flex-column"
            >
              {/* <NewslatterForm /> */}
              <Link to="/question-form">
                <Button
                  className="orange-dark is-bigger is-wide"
                  // onClick={() => setOpened(!isOpened)}
                >
                  Opýtajte sa nás
                </Button>
              </Link>
              <AnchorLink
                href="#aboutproduct"
                offset="100"
                className="anchor-link"
              >
                <FiChevronDown />
                <Translate content="homepage.banner.scroll-link" />
              </AnchorLink>
            </ScrollAnimation>
          </div>
          <div className="order-1 col-sm-12 order-md-2 col-md-6">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <div className="cs_banner">
                <div>
                  <img className="bg" src={bgslider} alt="banner-img" />
                </div>
                <img src={img4} alt="banner-img" />
                <img src={img3} alt="banner-img" />
                <img src={img2} alt="banner-img" />
                <img src={img1} alt="banner-img" />
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
