import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import '../ReadItem/ReadItem.scss';
import TextEllipsis from 'react-text-ellipsis';
import {Link} from 'react-router-dom';
import Translate from 'react-translate-component'


const ReadItem = (props) => {
    return (
        <div className="col-12 mt-4">
                     <Link to={{
                pathname: `/post/${props.id}`,
            state: {
                    id:props.id
                }
            }}>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="ReadItem">
                    <div className="cs_imgwrap">
                        <img src={props.img} alt="item-pic" />
                    </div>
                    <div className="cs_contentwrap">
                        <p className="cs_title">{props.headline}</p>
                        
                        <TextEllipsis
                            lines={4}
                            tag={'p'}
                            ellipsisChars={'...'}
                            tagClass={'cs_text'}
                        >
                            {props.text}
                        </TextEllipsis>
            
                        <small className="cs_date"><Translate content="post.published"/> {props.date}</small>
                    </div>
                </div>
            </ScrollAnimation>
            </Link>
        </div>
    );
}


export default ReadItem;