import React from "react";
import "../Newsletter/Newsletter.scss";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import SectionUnderText from "../../../../UI/SectionUnderText/SectionUnderText";
import Translate from "react-translate-component";
import ScrollAnimation from "react-animate-on-scroll";
import NewslatterForm from "../../../../newslatter-form";
import wave from "./../../../../../assets/images/transition-white.svg";

const Newsletter = props => {
  return (
    <section className="Newsletter">
      <img src={wave} alt="wave" className="cs_wave" />
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <SectionHeadline className="darker">
                <Translate content="homepage.newsletter.headline" />
              </SectionHeadline>
              <SectionUnderText className="darker newsletter_big_width">
                <Translate content="homepage.newsletter.text" unsafe={true} />
              </SectionUnderText>
            </ScrollAnimation>
          </div>
          <div className="col-12 mt-3 mb-3 text-center z-maxed">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={100}>
              <NewslatterForm className="newslatter-news" />
            </ScrollAnimation>
          </div>
          {/* <div className="col-12 text-center">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={200}>
              <small>
                <Translate content="homepage.newsletter.small_text" unsafe />
              </small>
            </ScrollAnimation>
          </div>
          */}
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
