import React from "react";
import PropTypes from "prop-types";
import { CFErrorSC } from "./contact-form.styled";

const ContactFormError = props => {
  const { errMsg } = props;
  return (
    <CFErrorSC>
      <div className="tooltip-arrow-up" />
      <div className="tooltip-box">{errMsg}</div>
    </CFErrorSC>
  );
};

export default ContactFormError;

ContactFormError.propTypes = {
  isVisible: PropTypes.bool,
  errMsg: PropTypes.string.isRequired
};

ContactFormError.defaultProps = {
  isVisible: false
};
