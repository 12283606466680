import React from "react";

function FAQ({ faq, index, toggleFAQ }) {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  );
}

export default FAQ;

// function FAQ({ open, question, answer, index, toggleFAQ }) {
//   return (
//     <div
//       className={"faq " + (open ? "open" : "")}
//       key={index}
//       onClick={() => toggleFAQ(index)}
//     >
//       <div className="faq-question">{question}</div>
//       <div className="faq-answer">{answer}</div>
//     </div>
//   );
// }

// export default FAQ;
