import React, { useContext } from "react";
import { Formik } from "formik";
import Translate from "react-translate-component";
import NLErrorElement from "../newslatter-form/newslatter-form-error";
import NLcheckboxSchema from "./checkbox-contact-form-rules";
import {
  CHSubmitBtnSC,
  CHCheckboxInputSC
} from "./checkbox-contact-form.styled";
import { StateContext } from "../../context/state-context-provider";
import Api from "../../utils/api";

export default () => {
  const { formValues, setCFormOpen, setContactSent } = useContext(StateContext);

  const formInitialValues = {
    terms: false,
    newslatter: false
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={NLcheckboxSchema}
      onSubmit={(values, { setSubmitting }) => {
        const sendContactMessage = async () => {
          const result = await Api.sendContactForm({
            first_name: formValues.name,
            last_name: formValues.surname,
            email: formValues.email,
            phone: formValues.phone,
            message: formValues.message,
            marketing_approval: values.newslatter
          });

          setSubmitting(false);

          if (result.status === 200) {
            setContactSent(true);
            setCFormOpen(false);
          }
        };

        sendContactMessage();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setTouched,
        validateForm,
        isValid,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => {
        const newslatterHasError =
          errors && errors.newslatter && touched.newslatter;
        const termsHasError = errors && errors.terms && touched.terms;

        return (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <CHCheckboxInputSC
                    type="checkbox"
                    hasError={termsHasError}
                    onBlur={handleBlur}
                    name="terms"
                    value="terms & conditions"
                    onChange={handleChange}
                    checked={values.terms || false}
                  />
                  <Translate
                    component="p"
                    content="homepage.newsletter.consent_text"
                    unsafe
                  />
                  {termsHasError ? (
                    <NLErrorElement errMsg={errors.terms} />
                  ) : null}
                </div>
                <div className="col-12 mt-3">
                  <CHCheckboxInputSC
                    type="checkbox"
                    hasError={newslatterHasError}
                    onBlur={handleBlur}
                    name="newslatter"
                    value="newslatter & conditions"
                    onChange={handleChange}
                    checked={values.newslatter || false}
                  />
                  <Translate
                    component="p"
                    content="homepage.newsletter.small_text"
                    unsafe
                  />
                  {newslatterHasError ? (
                    <NLErrorElement errMsg={errors.newslatter} />
                  ) : null}

                  <CHSubmitBtnSC
                    disabled={isSubmitting}
                    type="submit"
                    isSent={false}
                  >
                    Odoslať
                  </CHSubmitBtnSC>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
