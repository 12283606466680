import React from "react";
import {
  CookieWrapSC,
  CookieTextSC,
  CookieButtonSC,
} from "./cookie-consent.styled";
import Translate from "react-translate-component";
import TagManager from "react-gtm-module";

let consentApproved = localStorage.getItem("cookieConsent");

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CODE,
};

class CookieConsent extends React.PureComponent {
  state = {
    isDisabled: false,
  };

  approveConsent = () => {
    localStorage.setItem("cookieConsent", true);
    TagManager.initialize(tagManagerArgs);
    this.setState((state) => ({ ...state, isDisabled: true }));
    window.location.reload();
  };

  componentWillMount() {
    if (
      process.env.REACT_APP_IS_DEVELOPMENT === "false" &&
      consentApproved === true
    ) {
      TagManager.initialize(tagManagerArgs);
    } else {
      return;
    }
  }

  render() {
    const { isDisabled } = this.state;
    return (
      <>
        {consentApproved ? null : (
          <CookieWrapSC className={isDisabled ? "disabled" : null}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <CookieTextSC>
                    <Translate content="homepage.cookie-consent" />
                    <a
                      href={process.env.PUBLIC_URL + "/pdf/GDPR-Cookie.pdf"}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      na tejto adrese
                    </a>
                    .
                  </CookieTextSC>
                  <CookieButtonSC onClick={this.approveConsent}>
                    Súhlasím a pokračovať
                  </CookieButtonSC>
                </div>
              </div>
            </div>
          </CookieWrapSC>
        )}
      </>
    );
  }
}

export default CookieConsent;
