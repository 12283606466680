import styled from "styled-components";

export const NotFoundContSC = styled.div`
  width: 100%;
  min-height: 90vh;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #eeeadf;
  text-align: center;
`;

export const NotFoundHeadlineSC = styled.h2`
  font-family: "Cabin", sans-serif;
  font-size: 200px;
  padding-top: 20vh;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #413d37;
  @media (max-width: 767.98px) {
    font-size: 150px;
  }
`;

export const NotFoundTextSC = styled.div`
  margin: 36px auto 0 auto;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  padding: 0 15px;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #655f56;

  b {
    font-family: "Cabin", sans-serif;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #413d37;
  }
  a {
    color: #ff8b5f;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;
