import * as Yup from "yup";

const nameMinChars = 2;
const nameMaxChars = 100;

const msgMinChars = 2;
const msgMaxChars = 1000;

const surNameMinChars = 2;
const surNameMaxChars = 100;

export default Yup.object().shape({
  name: Yup.string()
    .required("Meno je povinné pole")
    .min(
      nameMinChars,
      `Vaše meno je príliš krátke. Minimálny počet charakterov je ${nameMinChars}`
    )
    .max(
      nameMaxChars,
      `Vaše meno je príliš dlhé. Maximálny počet charakterov je ${nameMaxChars}`
    ),
  surname: Yup.string()
    .required("Priezvisko je povinné pole")
    .min(
      surNameMinChars,
      `Vaše priezvisko je príliš krátke. Minimálny počet charakterov je ${surNameMinChars}`
    )
    .max(
      surNameMaxChars,
      `Vaše priezvisko je príliš dlhé. Maximálny počet charakterov je ${surNameMaxChars}`
    ),
  email: Yup.string()
    .required("Email je povinné pole")
    .email("Zadali ste chybný email")
    .required("Email je povinné pole"),
  phone: Yup.string().test(
    "validate-phone-number",
    "Zadali ste chybné telefónne číslo",
    function(value) {
      if (value === undefined) return true;
      const phoneRE = /^(?=.*[0-9])[- +()0-9]+$/;
      return phoneRE.test(value);
    }
  ),
  message: Yup.string()
    .required("Správa je povinné pole")
    .min(
      msgMinChars,
      `Vaše správa je príliš krátka. Minimálny počet charakterov je ${msgMinChars}`
    )
    .max(
      msgMaxChars,
      `Vaša správa je príliš dlhá. Maximálny počet charakterov je ${msgMaxChars}`
    ),
  terms: Yup.boolean()
    .oneOf([true], "Súhlas s podmienkami je povinný")
    .required("Súhlas je povinný"),
  newslatter: Yup.boolean()
});
