import React from "react";

import QFForm from "./question-form-form";
import { QFWrapperSC } from "./question-form.styled";

const QuestionForm = () => {
  return (
    <QFWrapperSC>
      <div className="cf-inner-wrapper">
        <QFForm />
      </div>
    </QFWrapperSC>
  );
};

export default QuestionForm;
