import * as Yup from "yup";

const nameMinChars = 2;
const nameMaxChars = 100;

const surNameMinChars = 2;
const surNameMaxChars = 100;

export default Yup.object().shape({
  first_name: Yup.string()
    .required("Meno je povinné pole")
    .min(
      nameMinChars,
      `Vaše meno je príliš krátke. Minimálny počet charakterov je ${nameMinChars}`
    )
    .max(
      nameMaxChars,
      `Vaše meno je príliš dlhé. Maximálny počet charakterov je ${nameMaxChars}`
    ),

  last_name: Yup.string()
    .required("Priezvisko je povinné pole")
    .min(
      surNameMinChars,
      `Vaše priezvisko je príliš krátke. Minimálny počet charakterov je ${surNameMinChars}`
    )
    .max(
      surNameMaxChars,
      `Vaše priezvisko je príliš dlhé. Maximálny počet charakterov je ${surNameMaxChars}`
    ),

  email: Yup.string()
    .required("Email je povinné pole")
    .email("Zadali ste chybný email")
    .required("Email je povinné pole"),

  phone: Yup.string()
    .test(
      "validate-phone-number",
      "Zadali ste chybné telefónne číslo",
      function(value) {
        if (value === undefined) return true;
        const phoneRE = /^(?=.*[0-9])[- +()0-9]+$/;
        return phoneRE.test(value);
      }
    )
    .required("Telefón je povinné pole"),

  merchant_name: Yup.string().required("Názov zariadenia je povinné pole"),

  merchant_address: Yup.string().required("Adresa zariadenia je povinné pole"),

  buildings: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Názov prevádzky je povinné pole"),
      address: Yup.string().required("Adresa prevádzky je povinné pole"),
      law_form: Yup.string().required("Právna forma je povinné pole"),
      founder: Yup.string().required("Zriadovateľ je povinné pole"),
      num_clients: Yup.number()
        .required("Počet klientov je povinné pole")
        .typeError("Zadajte číslo"),
      num_employees: Yup.number()
        .required("Počet zamestnancov je povinné pole")
        .typeError("Zadajte číslo"),
      email: Yup.string()
        .email("Zadali ste chybný email")
        .required("Email je povinné pole"),

      service_facility_seniors: Yup.boolean(),
      service_nursing_home: Yup.boolean(),
      service_nursing_facility: Yup.boolean(),
      service_specialized_facility: Yup.boolean(),
      service_daily_care: Yup.boolean(),
      service_day_nursery: Yup.boolean(),
      service_social_home: Yup.boolean(),

      another_service: Yup.string().when(
        [
          "service_facility_seniors",
          "service_nursing_home",
          "service_nursing_facility",
          "service_specialized_facility",
          "service_daily_care",
          "service_day_nursery",
          "service_social_home"
        ],
        {
          is: (
            service_facility_seniors,
            service_nursing_home,
            service_nursing_facility,
            service_specialized_facility,
            service_daily_care,
            service_day_nursery,
            service_social_home
          ) =>
            service_facility_seniors !== true &&
            service_nursing_home !== true &&
            service_nursing_facility !== true &&
            service_specialized_facility !== true &&
            service_daily_care !== true &&
            service_day_nursery !== true &&
            service_social_home !== true,
          then: Yup.string().required(
            "Vyberte aspoň jednu službu alebo zadajte inú službu"
          ),
          otherwise: Yup.string()
        }
      )
    })
  ),
  terms: Yup.boolean()
    .oneOf([true], "Súhlas s podmienkami je povinný")
    .required("Súhlas je povinný"),
  newsletter: Yup.boolean()
});
