import axios from "axios";
import { Formik } from "formik";
import React, { useContext } from "react";
import Translate from "react-translate-component";
import { StateContext } from "../../context/state-context-provider";
import NLErrorElement from "./newslatter-form-error";
import NLFormSchema from "./newslatter-form-rules";
import {
  CHCheckboxInputSC,
  NLSubmitBtnSC,
  NLTextInputSC,
  NLWrapperSC,
} from "./newslatter.styled";

export default (props) => {
  const formInitialValues = {
    email: "",
    newslatter: false,
  };

  const { emailAdress, setSucessOpen, setFormOpen } = useContext(StateContext);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={NLFormSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        // setTimeout(() => {
        //   setSubmitting(false);
        //   // reset current values
        //   setEmailAddress(values);
        //   setFormOpen(true);
        //   resetForm({});
        //   values = formInitialValues;
        // }, 400);
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/rest/newsletter`, {
            email: emailAdress.email,
          })
          .then((response) => {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          })
          .finally(function () {
            setSubmitting(false);
            setSucessOpen(true);
            setFormOpen(false);
            resetForm({});
          });

        // reset current values
        values = formInitialValues;
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setTouched,
        validateForm,
        isValid,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const emailHasError = errors && errors.email && touched.email;
        const newslatterHasError =
          errors && errors.newslatter && touched.newslatter;

        return (
          <NLWrapperSC className={props.className}>
            <form onSubmit={handleSubmit}>
              <NLTextInputSC
                type="email"
                name="email"
                filled={values.email}
                hasError={emailHasError}
                placeholder="Zadajte Váš email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email || ""}
              />
              {emailHasError ? (
                <NLErrorElement errMsg={errors.email} errClass="error-email" />
              ) : null}

              <NLSubmitBtnSC
                disabled={isSubmitting}
                type="submit"
                isSent={false}
              >
                Prihlásiť sa do newslettra
              </NLSubmitBtnSC>
              <div className="col-12 d-flex mt-3">
                <CHCheckboxInputSC
                  type="checkbox"
                  hasError={newslatterHasError}
                  onBlur={handleBlur}
                  name="newslatter"
                  value="newslatter & conditions"
                  onChange={handleChange}
                  checked={values.newslatter || false}
                />
                <Translate
                  component="p"
                  content="homepage.newsletter.small_text"
                  unsafe
                  className="checkbox-text"
                />
                {newslatterHasError ? (
                  <NLErrorElement errMsg={errors.newslatter} />
                ) : null}
              </div>
            </form>
          </NLWrapperSC>
        );
      }}
    </Formik>
  );
};
