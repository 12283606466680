import React from "react";
import "../Questions/Questions.scss";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import Translate from "react-translate-component";
import AccordionQuestions from "./Accordion/Accordion";
import ScrollAnimation from "react-animate-on-scroll";

const Questions = props => {
  return (
    <section id={props.scrollAnchor} className="Questions">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 mb-2">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <SectionHeadline className="dark">
                <Translate content="homepage.questions.headline" />
              </SectionHeadline>
            </ScrollAnimation>
          </div>

          <div className=" accordion col-12 pl-0 pr-0">
            <AccordionQuestions />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questions;
