import React from 'react';
import './Button.scss';


const Button = (props) => {
  return (
    <button onClick={props.onClick} className={`basic_btn ${props.className}`}>{props.children}</button>
  );
}

export default Button;