import React from "react";
import "../AboutProduct/AboutProduct.scss";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import SectionUnderText from "../../../../UI/SectionUnderText/SectionUnderText";
import img1 from "../../../../../assets/images/benefits-img-1.png";
import img2 from "../../../../../assets/images/benefits-img-2.png";
import img3 from "../../../../../assets/images/benefits-img-3.png";
import img4 from "../../../../../assets/images/benefits-img-4.png";
import img5 from "../../../../../assets/images/benefits-img-5.png";
import img6 from "../../../../../assets/images/benefits-img-6.png";
import videoImg1 from "../../../../../assets/images/thumbnail-video-1@3x.png";
import videoImg2 from "../../../../../assets/images/thumbnail-video-2@3x.png";
import videoImg3 from "../../../../../assets/images/thumbnail-video-3@3x.png";
import Translate from "react-translate-component";
import AboutItem from "./AboutItem/AboutItem";
import AboutVideo from "./AboutVideo/AboutVideo";
import ScrollAnimation from "react-animate-on-scroll";

const AboutProduct = props => {
  return (
    <section id={props.scrollAnchor} className="AboutProduct">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 mb-2">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <SectionHeadline className="dark">
                <Translate content="homepage.product.headline" />
              </SectionHeadline>
            </ScrollAnimation>
          </div>

          <div className="col-md-12">
            <SectionUnderText className="dark full-width">
              <Translate content="homepage.product.text" unsafe={true} />{" "}
              <Translate content="homepage.product.text_2" unsafe={true} />{" "}
              <Translate content="homepage.product.text_3" unsafe={true} />
              <Translate content="homepage.product.text_4" unsafe={true} />
            </SectionUnderText>
          </div>

          <AboutVideo
            headline={
              <Translate content="homepage.product.videos.video-1-headline" />
            }
            text={
              <Translate
                content="homepage.product.videos.video-1-text"
                unsafe={true}
              />
            }
            img={videoImg1}
            url={"https://youtu.be/moJAxwoMYRI"}
          />

          <AboutVideo
            headline={
              <Translate content="homepage.product.videos.video-2-headline" />
            }
            text={
              <Translate
                content="homepage.product.videos.video-2-text"
                unsafe={true}
              />
            }
            img={videoImg2}
            url={"https://youtu.be/sq-bhatOicY"}
          />

          <AboutVideo
            headline={
              <Translate content="homepage.product.videos.video-3-headline" />
            }
            text={
              <Translate
                content="homepage.product.videos.video-3-text"
                unsafe={true}
              />
            }
            img={videoImg3}
            url={"https://youtu.be/MNi5Ufsd6pQ"}
            className="last-video"
          />

          <AboutItem
            headline={
              <Translate content="homepage.product.items.item-1-headline" />
            }
            text={
              <Translate
                content="homepage.product.items.item-1-text"
                unsafe={true}
              />
            }
            img={img1}
          />

          <AboutItem
            headline={
              <Translate content="homepage.product.items.item-2-headline" />
            }
            text={
              <Translate
                content="homepage.product.items.item-2-text"
                unsafe={true}
              />
            }
            img={img2}
            delay={100}
          />

          <AboutItem
            headline={
              <Translate content="homepage.product.items.item-3-headline" />
            }
            text={
              <Translate
                content="homepage.product.items.item-3-text"
                unsafe={true}
              />
            }
            img={img3}
            delay={200}
          />

          <AboutItem
            headline={
              <Translate content="homepage.product.items.item-4-headline" />
            }
            text={
              <Translate
                content="homepage.product.items.item-4-text"
                unsafe={true}
              />
            }
            img={img4}
            delay={300}
          />

          <AboutItem
            headline={
              <Translate content="homepage.product.items.item-5-headline" />
            }
            text={
              <Translate
                content="homepage.product.items.item-5-text"
                unsafe={true}
              />
            }
            img={img5}
            delay={400}
          />

          <AboutItem
            headline={
              <Translate content="homepage.product.items.item-6-headline" />
            }
            text={
              <Translate
                content="homepage.product.items.item-6-text"
                unsafe={true}
              />
            }
            img={img6}
            delay={500}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutProduct;
