import React from "react";
import { Link } from "react-router-dom";
import "./FormSuccess.scss";
import img from "../../../../../assets/images/form-sent.png";
import Translate from "react-translate-component";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import SectionUnderText from "../../../../UI/SectionUnderText/SectionUnderText";
import ScrollAnimation from "react-animate-on-scroll";
import Button from "../../../../UI/Button/Button";

const FormSuccess = props => {
  return (
    <section id={props.scrollAnchor} className="FormSuccess">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="cs_success">
              {/* <img className="bg" src={imgBg} alt="banner-img" /> */}
              <img src={img} alt="banner-img" />
            </div>
          </div>

          <div className="col-md-6">
            <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
              <SectionHeadline className="dark left">
                <Translate content="questionaire.sent.success" />
              </SectionHeadline>
              <SectionUnderText className="dark left">
                <Translate
                  content="questionaire.sent.thanks_for_answers"
                  unsafe
                />
              </SectionUnderText>
              <Link to="/">
                <Button className="basic_btn is-bigger is-wider bordered dark thin-border mt-5 mb-5">
                  Späť na hlavnú stránku
                </Button>
              </Link>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FormSuccess;
