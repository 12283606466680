import React from "react";
import {
  NotFoundContSC,
  NotFoundHeadlineSC,
  NotFoundTextSC
} from "./not-found.styled";
import { Link } from "react-router-dom";

export default () => {
  return (
    <NotFoundContSC>
      <NotFoundHeadlineSC>404</NotFoundHeadlineSC>
      <NotFoundTextSC>
        <b>Stránka neexistuje.</b>
        <br />
        Prejdite na <Link to="/">domovskú stránku</Link> a skúste štastie znova.
      </NotFoundTextSC>
    </NotFoundContSC>
  );
};
