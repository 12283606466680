import React, { useEffect } from "react";
import "./About.scss";
import AboutUs from "./Sections/AboutUs/AboutUs";
import Partners from "./Sections/Partners/Partners";
import Team from "./Sections/Team/Team";
class Homepage extends React.Component {
  render() {
    function ScrollToTopOnMount() {
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      return null;
    }
    return (
      <>
        <ScrollToTopOnMount />
        <div className="tophalf-bg">
          <AboutUs />
          <Team />
          {/* <RoadMap /> */}
        </div>
        <div className="bottomhalf-bg">
          <Partners scrollAnchor="partners" />
        </div>
      </>
    );
  }
}
export default Homepage;
