import styled from "styled-components";
import checked from "../../assets/images/checked.svg";

export const CFWrapperSC = styled.div`
  width: 100%;
  height: auto;
  button,
  input,
  textarea {
    outline: none;
  }

  .cf-inner-wrapper {
    position: relative;
    display: block;

    p {
      font-size: 16px;
    }

    .cf-close-btn-wrapper {
      height: 18px;
      line-height: 0.9;
      margin-top: 25px;

      .cf-close-btn {
        position: absolute;
        right: 25px;
        padding: 0;
        line-height: 0.9;

        width: 18px;
        height: 18px;

        background-color: transparent;
        border-width: 0;
      }
    }

    .cf-heading {
      font-family: "Playfair Display", sans-serif;
      margin-top: 21px;
      margin-bottom: 0;
    }

    form {
      display: flex;
      flex-direction: column;

      input {
        margin-top: 0px;
      }

      textarea {
        margin-top: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    .col-md-6 {
      padding: 0px;
    }
    .col-md-12 {
      padding: 0px;
    }
  }
`;

export const CFTextInputSC = styled.input`
  border-radius: 8px;
  min-width: 100%;
  width: 100%;
  padding: 20px;
  border: 1px solid #dcd9d6;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  height: 60px;

  &::placeholder {
    color: #b9b9b9;
  }

  &:focus,
  &:hover {
    border: 1px solid #ff8b5f;
  }

  ${props => (props.hasError ? `color: #E33200;` : null)}
  ${props => (props.hasError ? `border-color: #E33200;` : null)}
`;

export const CFTextAreaSC = styled.textarea`
  border-radius: 2px;
  border: 1px solid rgba(65, 61, 55, 0.3);
  background-color: #ffffff;
  padding: 9px 15px;
  min-height: 150px;
  max-height: 400px;
  min-width: 100%;
  margin-top: 0;

  &::placeholder {
    color: #a6a6a6;
  }

  &:focus,
  &:hover {
    border: 1px solid #ff8b5f;
  }

  ${props => (props.hasError ? `color: #E33200;` : null)}
  ${props => (props.hasError ? `border-color: #E33200;` : null)}
`;

export const CFSubmitBtnSC = styled.button`
  font-family: "Cabin", sans-serif;
  width: 255px;
  height: 56px;
  border-radius: 30px;
  color: #ffffff;
  float: right;
  font-weight: bold;
  border-width: 0;
  background: #ff815a;
  margin-top: 16px;
  font-size: 18px;

  &:disabled {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const CFErrorSC = styled.div`
  height: 42px;
  padding-top: 3px;

  div.tooltip-arrow-up {
    width: 0;
    height: 0;
    margin-left: 15px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #e32c2c;
  }

  div.tooltip-box {
    background-color: #e33200;
    width: fit-content;
    padding: 8px 12px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CFSuccessScreenSC = styled.div`
  h4 {
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }

  .submit-btn-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const CFSuccessMessageSC = styled.p`
  position: relative;
  left: 0;
  left: 30px;
  background: gainsboro;
  background: gray;
  background: green;
  max-width: 506px;
  width: 100%;
  margin: 20px 0 0 0;
  padding: 10px 20px;
  color: white;
  float: left;
  font-size: 16px;
  border-radius: 2px;
  background-color: #00c91d;
`;

export const CFSectionSC = styled.div`
  background-color: white;
  padding: 50px;
  border: none;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  .fill-content {
    p {
      font-size: 14px;
      color: #4a463f;
      display: flex;
      margin-bottom: 10px;
      margin-top: 40px;

      &.checkbox-text {
        font-size: 14px;
        text-align: left;
        max-width: calc(100% - 50px);
        display: inline-block;
        margin-top: 0px;
        margin-left: 24px;

        a {
          color: #ff672e;

          &:focus,
          :hover {
            text-decoration: underline;
          }
        }
      }
    }

    .services-header {
      color: #413d37;
      font-size: 24px;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
    }
  }

  &.first-section {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .last-section {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  @media (max-width: 576px) {
    padding: 25px;
  }
`;

export const CHCheckboxInputSC = styled.input`
cursor:pointer;
  border-radius: 3px;
  border: solid 1px #DCD9D6;
  padding: 0;
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 2px;
  width: 24px;
  height: 24px;
  ${props =>
    props.checked
      ? `background-image: url(${checked});background-color:#ff8b5f`
      : null}
  ${props => (props.hasError ? `color: #E33200;` : null)}
  ${props => (props.hasError ? `border-color: #E33200 !important;` : null)}
`;
