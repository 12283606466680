import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "../NewestItem/NewestItem.scss";
import TextEllipsis from "react-text-ellipsis";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";

const NewestItem = props => {
  return (
    <div className="col-md-6 col-xl-6 mt-4">
      <Link
        to={{
          pathname: `/post/${props.id}`,
          state: {
            id: props.id
          }
        }}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <div className="NewestItem">
            <div className="cs_imgwrap">
              <img src={props.img} alt="item-pic" />
            </div>
            <div className="cs_contentwrap">
              <p className="cs_title">{props.headline}</p>
              <TextEllipsis
                lines={3}
                tag={"p"}
                ellipsisChars={"..."}
                tagClass={"cs_text"}
              >
                {props.text}
              </TextEllipsis>
              <small className="cs_date">
                <Translate content="post.published" /> {props.date}
              </small>
            </div>
          </div>
        </ScrollAnimation>
      </Link>
    </div>
  );
};

export default NewestItem;
