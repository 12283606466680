import React, { useContext, useEffect } from "react";
import { StateContext } from "../../../context/state-context-provider";
import img from "../../../assets/images/form-ilustracia.svg";
import "./Questionnaire.scss";
import Translate from "react-translate-component";
import QuestionForm from "../../question-form/index";
import CheckboxContactForm from "../../checkbox-contact-form";
import CSModal from "../../UI/Modal/Modal";

const Questionaire = () => {
  const { cformOpen, setCFormOpen } = useContext(StateContext);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "normal"
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <section className="Questionnaire">
      <div className="container">
        <div className="row">
          <div className="order-2 col-sm-12 order-md-1 col-md-6">
            <div className="cs_contact_info">
              <Translate content="questionaire.headline" component="h1" />

              <Translate
                className="mt-4"
                content="questionaire.text"
                component="p"
              />
              <Translate
                className="mt-4 mb-0"
                content="questionaire.thank_you"
                component="p"
              />
            </div>
          </div>

          <div className="order-1 col-sm-12 order-md-2 col-md-6">
            <img className="cs_top_img" src={img} alt="form-img" />
          </div>
        </div>
        <div className="row">
          <div className=" question-area col-md-12">
            {/* <SectionHeadline className="dark small left">
              <Translate className="mb-0" content="contact.contact_us" />
            </SectionHeadline> */}

            <QuestionForm />

            {cformOpen ? (
              <CSModal
                show={cformOpen}
                onHide={() => setCFormOpen(false)}
                className="checkbox-modal"
              >
                <CheckboxContactForm />
              </CSModal>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questionaire;
