import React from "react";
import "../BlogsItem/BlogsItem.scss";
import ScrollAnimation from "react-animate-on-scroll";
import TextEllipsis from "react-text-ellipsis";
import { Link } from "react-router-dom";
import Translate from "react-translate-component";

const BlogsItem = props => {
  const specialStyle = {};
  const { secondRow } = props;

  if (secondRow) {
    specialStyle.marginTop = "0 !important";
  }

  return (
    <div className={props.bootstrapClass} style={specialStyle}>
      <Link
        to={{
          pathname: `/post/${props.id}`,
          state: {
            id: props.id
          }
        }}
      >
        <ScrollAnimation
          animateIn="fadeIn"
          animateOnce={true}
          delay={props.delay}
        >
          <div className="BlogsItem">
            <div className="cs_imgwrap">
              <img src={props.img} alt="item-pic" />
            </div>
            <div className="cs_contentwrap">
              <div>
                <p className="cs_title">{props.headline}</p>

                <TextEllipsis
                  lines={4}
                  tag={"p"}
                  ellipsisChars={"..."}
                  tagClass={"cs_text"}
                >
                  {props.text}
                </TextEllipsis>
              </div>
              <small className="cs_date">
                <Translate content="post.published" /> {props.date}
              </small>
            </div>
          </div>
        </ScrollAnimation>
      </Link>
    </div>
  );
};

export default BlogsItem;
