import React, { useContext } from "react";
import { Formik } from "formik";
import NLErrorElement from "../newslatter-form/newslatter-form-error";
import NLcheckboxSchema from "./checkbox-form-rules";
import { CHSubmitBtnSC, CHCheckboxInputSC } from "./checkbox-form.stlyed";
import { StateContext } from "../../context/state-context-provider";
import Translate from "react-translate-component";
import axios from "axios";

export default () => {
  const { emailAdress, setSucessOpen, setFormOpen } = useContext(StateContext);

  const formInitialValues = {
    newslatter: false
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={NLcheckboxSchema}
      onSubmit={(values, { setSubmitting }) => {
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/rest/newsletter`, {
            email: emailAdress.email
          })
          .then(response => {
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          })
          .finally(function() {
            setSubmitting(false);
            setSucessOpen(true);
            setFormOpen(false);
          });

        // reset current values
        values = formInitialValues;
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setTouched,
        validateForm,
        isValid,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => {
        const newslatterHasError =
          errors && errors.newslatter && touched.newslatter;

        return (
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <CHCheckboxInputSC
                    type="checkbox"
                    hasError={newslatterHasError}
                    onBlur={handleBlur}
                    name="newslatter"
                    value="newslatter & conditions"
                    onChange={handleChange}
                    checked={values.newslatter || false}
                  />

                  <Translate
                    component="p"
                    content="homepage.newsletter.small_text"
                    unsafe
                  />
                  {newslatterHasError ? (
                    <NLErrorElement errMsg={errors.newslatter} />
                  ) : null}
                </div>
              </div>
            </div>

            <CHSubmitBtnSC disabled={isSubmitting} type="submit" isSent={false}>
              Odoslať
            </CHSubmitBtnSC>
          </form>
        );
      }}
    </Formik>
  );
};
