import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import { detect } from "detect-browser";

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

const browser = detect();
const version = browser.version;
const dotPosition = version.indexOf(".");
const numberedVersion = parseInt(version.slice(0, dotPosition));

if (browser && browser.name === "ie" && numberedVersion < 9) {
  const appElem = document.getElementById("app");
  appElem.innerHTML =
    "<p>We are sorry but your browser is outdated. Please " +
    '<a style="color: blue; text-decoration: underline" href="https://browsehappy.com" target="_self">' +
    "update your browser</a> to current version.</p>";
} else {
  ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById("app")
  );
}

serviceWorker.unregister();
