import React from "react";
import "./AboutUs.scss";
import img from "../../../../../assets/images/onas-obrazok.png";
import imgBg from "../../../../../assets/images/okrasa-srdce-otocene.svg";
import Translate from "react-translate-component";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import SectionUnderText from "../../../../UI/SectionUnderText/SectionUnderText";
import ScrollAnimation from "react-animate-on-scroll";

const AboutUs = props => {
  return (
    <section id={props.scrollAnchor} className="AboutUs">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="cs_about">
              <img className="bg-img" src={imgBg} alt="about-img" />
              <img src={img} alt="about-img" />
            </div>
            <div className="cs_quote">
              <p>
                <span className="bold-text">
                  <Translate content="homepage.about.quote-1" unsafe />{" "}
                </span>
                <Translate content="homepage.about.quote-2" unsafe />
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
              <SectionHeadline className="dark left">
                <Translate content="homepage.about.headline" />
              </SectionHeadline>
              <SectionUnderText className="dark left">
                <Translate content="homepage.about.text" unsafe />
              </SectionUnderText>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
