import React from "react";
import { Modal } from "react-bootstrap";
import "../Modal/Modal.scss";
import modal_close from "../../../assets/images/modal-closer.svg";
import video_close from "../../../assets/images/button-close.svg";

class CSModal extends React.Component {
  render() {
    return (
      <Modal
        {...this.props}
        className={`CSModal ${this.props.className}`}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <button className="cs_modal_closer" onClick={this.props.onHide}>
            <img src={modal_close} alt="close" />
            <img src={video_close} alt="close" />
          </button>
          {this.props.children}
        </Modal.Body>
      </Modal>
    );
  }
}

export default CSModal;
