import React, { useState, Children } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

export const StateContext = React.createContext();

const StateProvider = props => {
  const [emailAdress, setEmailAddress] = useState("");
  const [formOpen, setFormOpen] = useState(false);
  const [cformOpen, setCFormOpen] = useState(false);
  const [isSucessOpen, setSucessOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [contactSent, setContactSent] = useState(false);
  const [keyCounter, setKeyCounter] = useState(null);

  const { children } = props;
  return (
    <StateContext.Provider
      value={{
        emailAdress,
        setEmailAddress,
        formOpen,
        setFormOpen,
        formValues,
        setFormValues,
        isSucessOpen,
        setSucessOpen,
        contactSent,
        setContactSent,
        cformOpen,
        setCFormOpen,
        keyCounter,
        setKeyCounter
      }}
    >
      {Children.only(children)}
    </StateContext.Provider>
  );
};

// export default connect(state => state.app.user.loggedIn, () => {})(ServiceProvider);
export default withRouter(StateProvider);

StateProvider.propTypes = {
  children: PropTypes.node.isRequired
};
