import React from 'react';
import '../SectionUnderText/SectionUnderText.scss';

const SectionUnderText = (props) => {
    return(
        <p className={`SectionUnderText ${props.className}`}>{props.children}</p>
    );
}


export default SectionUnderText;