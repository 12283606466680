import React from "react";
import FormSuccess from "./Sections/FormSuccess/FormSuccess";
import "./FormSent.scss";
class Homepage extends React.Component {
  render() {
    return (
      <>
        <div className="tophalf-bg">
          <FormSuccess />
        </div>
      </>
    );
  }
}
export default Homepage;
