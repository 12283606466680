import React from "react";
import PropTypes from "prop-types";
import { QFErrorSC } from "./question-form.styled";

const QuestionFormError = props => {
  const { errMsg } = props;
  return (
    <QFErrorSC>
      <div className="tooltip-arrow-up" />
      <div className="tooltip-box">{errMsg}</div>
    </QFErrorSC>
  );
};

export default QuestionFormError;

QuestionFormError.propTypes = {
  isVisible: PropTypes.bool,
  errMsg: PropTypes.string.isRequired
};

QuestionFormError.defaultProps = {
  isVisible: false
};
