import React, { useContext } from "react";
import { StateContext } from "../../context/state-context-provider";
import "../Footer/Footer.scss";
import logo from "../../assets/images/caresee-logo-white.svg";
import CSModal from "../UI/Modal/Modal";
import SucModal from "../UI/Modal/success-modal";
import Navigation from "../Navigation/Navigation";
import CheckboxForm from "../checkbox-form";

const Footer = () => {
  const { isSucessOpen, setSucessOpen, formOpen, setFormOpen } = useContext(
    StateContext
  );
  const curDate = new Date();
  const actualYear = curDate.getFullYear();
  return (
    <footer className="Footer">
      {formOpen ? (
        <CSModal
          show={formOpen}
          onHide={() => setFormOpen(false)}
          className="checkbox-modal"
        >
          <CheckboxForm />
        </CSModal>
      ) : null}
      {isSucessOpen ? (
        <SucModal
          show={isSucessOpen}
          onHide={() => {
            setSucessOpen(false);
          }}
          className="success-modal"
        />
      ) : null}

      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-3">
            <img src={logo} alt="caresee-logo" className="cs_logo" />
          </div>
          <div className="col-sm-12 col-lg-9 text-right">
            <Navigation
              className="bordered light thin-border is-bigger"
              btnQuestionnaire="btn-questionnaire display-none"
            />
          </div>
          <div className="col-12 mb-3">
            <small>
              © {actualYear} CareSee s.r.o., Všetky práva vyhradené <br />
              CareSee s.r.o., Bernolákova 386/4, Holíč 908 51, IČO: 52 591 581,
              DIČ: 212 110 6339 <br />
              Zapísaná v Obchodnom registri Okresného súdu Trnava, Oddiel: Sro,
              Vložka č. 45386/T
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
