import styled, { keyframes } from "styled-components";

const disappear = keyframes`
  from {
    transform: translateY(0%)
  }

  to {
    transform: translateY(100%)
  }
`;

const appear = keyframes`
  from {
    transform: translateY(100%)
  }

  to {
    transform: translateY(0%)
  }
`;

export const CookieWrapSC = styled.div`
position:fixed
width:100%;
z-index:10000;
background:white;
bottom:0px;
padding:24px 0;
animation: ${appear} .3s linear;

&.disabled{
  animation: ${disappear} .5s linear;
  transform: translateY(100%)
}

.col-md-12{
    display:flex;

    @media screen and (max-width: 992px) {
        display: block;
        text-align:center;
      }
}

`;

export const CookieTextSC = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin: 0px;
  color: #655f56;
  flex: 1;
  a {
    color: #ff8b5f;
    text-decoration: underline;
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    display: block;
  }
`;

export const CookieButtonSC = styled.button`
  width: 100%;
  max-width: 255px;
  align-self: flex-end;
  height: 45px;
  border-radius: 22.5px;
  border: none;
  color: white;
  background-image: linear-gradient(to top, #ff7554, #ffb070);
  @media screen and (max-width: 992px) {
    margin-top: 20px;
  }
`;
