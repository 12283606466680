import React from "react";
import "../Solution/Solution.scss";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import SectionUnderText from "../../../../UI/SectionUnderText/SectionUnderText";
import Translate from "react-translate-component";
import webImage from "./../../../../../assets/images/userarea-app-image.png";
import tabletImage from "./../../../../../assets/images/caresee-app.png";

import wave from "./../../../../../assets/images/transition-orange.svg";
import AccordionQuestions from "./Accordion/Accordion";
import AccordionQuestionsWeb from "./Accordion/AccordionWeb";

import ScrollAnimation from "react-animate-on-scroll";

export class Solution extends React.Component {
  constructor(props) {
    super(props);

    this.state = { image: "screen" };
  }

  toggleButtons = value => {
    this.setState({ image: value });
  };
  render() {
    return (
      <section id={this.props.scrollAnchor} className="Solution">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 mb-2">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <SectionHeadline className="dark">
                  <Translate content="homepage.solution.headline" />
                </SectionHeadline>
              </ScrollAnimation>
            </div>

            <div className="col-md-12">
              <SectionUnderText className="dark full-width">
                <Translate content="homepage.solution.text" unsafe={true} />{" "}
                <Translate content="homepage.solution.text_2" unsafe={true} />{" "}
                <Translate content="homepage.solution.text_3" unsafe={true} />
              </SectionUnderText>
              <div className="toggle-buttons">
                <button
                  className={this.state.image === "screen" ? "is-active" : null}
                  onClick={() => this.toggleButtons("screen")}
                >
                  Aplikácia
                </button>
                <button
                  className={this.state.image === "tablet" ? "is-active" : null}
                  onClick={() => this.toggleButtons("tablet")}
                  style={{ lineHeight: "1rem" }}
                >
                  Používateľská zóna
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              {this.state.image === "screen" && <AccordionQuestions />}
              {this.state.image === "tablet" && <AccordionQuestionsWeb />}
            </div>
            <div className="col-sm-12 col-lg-6">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                {this.state.image === "screen" && (
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <img
                      src={tabletImage}
                      alt="screen-img"
                      className="img-right"
                    />
                  </div>
                )}
                {this.state.image === "tablet" && (
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <img
                      src={webImage}
                      alt="tablet-img"
                      className="img-right"
                    />
                  </div>
                )}
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <img src={wave} alt="wave-about" className="cs_wave" />
      </section>
    );
  }
}

export default Solution;
