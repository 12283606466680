import React, { Fragment } from "react";
import "../Post/Post.scss";
import Button from "../../../UI/Button/Button";
import { FiChevronLeft } from "react-icons/fi";
import {
  FacebookShareButton,
  FacebookShareCount,
  LinkedinShareButton
} from "react-share";
import SectionHeadline from "../../../UI/SectionHeadline/SectionHeadline";
import { Link } from "react-router-dom";
import BlogsItem from "../../Homepage/Sections/News/BlogsItem/BlogsItem";
import Translate from "react-translate-component";
import vawe from "../../../../assets/images/waves/najcitanejsie-top.svg";
import axios from "axios";
import Interweave from "interweave";
import { Helmet } from "react-helmet";

class Post extends React.Component {
  _isMounted = false;
  _secMounted = false;

  state = {
    blogPost: {},
    similar: []
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;

    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rest/blogs/${this.props.match.params.id}`
      )
      .then(res => {
        if (this._isMounted) {
          const currentPost = res.data;
          this.setState({ blogPost: currentPost });
        }
      })
      .catch(error => {
        console.log(error);
      });

    this._secMounted = true;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/rest/blogs`)
      .then(res => {
        if (this._secMounted) {
          const similar = res.data.items;
          this.setState({ similar });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._secMounted = false;
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{this.state.blogPost.title}</title>
          <meta name="description" content={this.state.blogPost.description} />
        </Helmet>
        <section className="Post">
          <div className="cs_bg_vawe">
            <img src={vawe} alt="blog-vawe" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="cs_post_body">
                  <Link to="/blog">
                    <Button className="bordered dark cs_post_btn">
                      <FiChevronLeft />
                      <Translate content="post.back_btn" />
                    </Button>
                  </Link>
                  <h1 className="cs_post_headline">
                    {this.state.blogPost.title}
                  </h1>
                  <div className="cs_post_img">
                    <img
                      src={this.state.blogPost.headingImageURL}
                      alt={this.state.blogPost.imageDescription}
                    />
                  </div>
                  <div className="cs_post_content">
                    <Interweave
                      content={this.state.blogPost.text}
                      allowList={["iframe"]}
                      allowElements
                    />
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <small className="cs_post_small">
                        <Translate content="post.published" />{" "}
                        {this.state.blogPost.publishedAt}
                      </small>
                    </div>
                    <div className="col-6 text-right">
                      <small className="cs_post_small">
                        {" "}
                        <Translate content="post.author" />{" "}
                        {this.state.blogPost.author}
                      </small>
                    </div>

                    <div className="col-12 mt-4">
                      <LinkedinShareButton url={window.location.href}>
                        <div id="share-button" className="linked">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                          </svg>
                          <Translate content="post.share" component="span" />
                        </div>
                      </LinkedinShareButton>

                      <FacebookShareButton url={window.location.href}>
                        <div id="share-button" className="fb">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
                          </svg>
                          <Translate content="post.share" component="span" />
                        </div>
                      </FacebookShareButton>

                      <FacebookShareCount url={window.location.href}>
                        {shareCount => (
                          <span className="cs_count">{shareCount}</span>
                        )}
                      </FacebookShareCount>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5">
                <SectionHeadline className="dark x_small left">
                  <Translate content="post.similar" />
                </SectionHeadline>

                <div className="row">
                  {this.state.similar
                    .slice(
                      this.state.similar.length - 3,
                      this.state.similar.length
                    )
                    .map(item => {
                      return (
                        <BlogsItem
                          bootstrapClass="col-md-6 col-xl-4 mt-4"
                          key={item.id}
                          id={item.id}
                          externalUrl={item.externalUrl}
                          headline={item.title}
                          text={item.description}
                          img={item.headingImageURL}
                          date={item.publishedAt}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Post;
