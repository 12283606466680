import React from "react";
import "./Privacy.scss";
import SectionHeadline from "../../UI/SectionHeadline/SectionHeadline";
import Translate from "react-translate-component";

class Privacy extends React.Component {
  render() {
    return (
      <section className="Privacy">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionHeadline className="dark mt-5 mb-4">
                <Translate content="privacy.title" />
              </SectionHeadline>
            </div>

            <div className="col-12 privacy-content">
              <Translate
                className="mb-0"
                content="privacy.paragraph"
                component="div"
                unsafe
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Privacy;
