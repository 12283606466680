import styled from "styled-components";
import checked from "../../assets/images/checked.svg";

export const NLWrapperSC = styled.div`
  width: 100%;
  height: auto;
  button,
  input,
  textarea {
    outline: none;
  }

  p {
    font-size: 14px;
    color: #4a463f;
    display: flex;
    margin-bottom: 10px;
    margin-top: 40px;

    &.checkbox-text {
      font-size: 14px;
      text-align: left;
      max-width: calc(100% - 50px);
      display: inline-block;
      margin-top: 0px;
      margin-left: 24px;

      a {
        color: #ff672e;

        &:focus,
        :hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  /* .newslatter-err {
    margin-top: 30px;
  } */

  &.newslatter-news {
    max-width: 729px;
    margin: 0 auto;

    input {
      max-width: 445px;
      height: 56px;
      border-radius: 30px;
      color: #413d37;
      background-color: transparent;
      border: 1px solid #dcd9d6;
      ::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
    }
    button {
      max-width: 255px;
      height: 56px;
      border-radius: 30px;
      color: #fff;
      background: #ff815a;
      &:disabled {
        color: rgba(0, 0, 0, 0.5) !important;
      }
    }

    @media screen and (max-width: 992px) {
      .newslatter-err {
        position: absolute !important;
      }
      input {
        max-width: 270px;
      }
      button {
        max-width: 270px;
      }
    }
    @media screen and (max-width: 768px) {
      .newslatter-err {
        position: absolute;
      }
      input {
        max-width: 270px;
        margin-right: 0px;
      }
      button {
        max-width: 270px;
      }
    }
  }

  &.newslatter-blog {
    input {
      border: 1px solid #413d37;
    }
    button {
      max-width: 270px;
      margin-top: 15px;
    }
    .newslatter-err {
      position: absolute;
    }

    @media screen and (max-width: 992px) {
      .newslatter-err {
        position: absolute;
      }
    }
    @media screen and (max-width: 768px) {
      .newslatter-err {
        position: absolute;
      }
    }
  }
`;

export const NLTextInputSC = styled.input`
  max-width: 270px;
  width: 100%;
  height: 45px;
  border-radius: 22.5px;
  border: 1px solid transparent;
  padding: 10px 20px;
  outline: none;
  box-shadow: none;
  margin-right: 15px;

  @media screen and (max-width: 992px) {
    margin-right: 0px;
  }

  /* &::placeholder {
    color: #c1c1c1 !important;
  } */
  &:focus,
  &:hover {
    border: 1px solid #000000;
  }
  &:-webkit-autofill {
    &:hover,
    &:focus {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      border: 1px solid black;
    }
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    border: 1px solid black;
  }

  ${props => (props.hasError ? `color: #E33200;` : null)}
  ${props => (props.hasError ? `border-color: #E33200 !important;` : null)}
`;

export const NLSubmitBtnSC = styled.button`
  font-family: "Cabin", sans-serif;
  font-size: 18px;
  width: 100%;
  padding: 10px 10px 9px 10px;
  border-radius: 22.5px;
  font-weight: 500;
  display: inline-block;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  color: #fff;
  background-image: linear-gradient(to top, #ff7554, #ffb070);
  line-height: 1;

  @media screen and (max-width: 992px) {
    max-width: 270px;
    margin-top: 10px;
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.5) !important;
  }
`;

export const NLErrorSC = styled.div`
  height: 42px;
  padding-top: 3px;
  position: absolute;
  z-index: 999;
  margin-top: 25px;

  &.error-email {
    margin-top: 0px;
    margin-left: 50px;
  }
  /* @media screen and (max-width: 992px) {
    position: relative;
  } */
  div.tooltip-arrow-up {
    width: 0;
    height: 0;
    margin-left: 15px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #e32c2c;
  }

  div.tooltip-box {
    background-color: #e33200;
    width: fit-content;
    padding: 8px 12px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CHCheckboxInputSC = styled.input`
cursor:pointer;
  border-radius: 3px!important;
  border: solid 1px #ff8b5f;
  padding: 0;
  position: relative;
  display: block;
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 2px;
  width: 24px!important;
  height: 24px!important;
  ${props =>
    props.checked
      ? `background-image: url(${checked});background-color:#ff8b5f!important`
      : null}
  ${props => (props.hasError ? `color: #E33200;` : null)}
  ${props => (props.hasError ? `border-color: #E33200 !important;` : null)}
`;
