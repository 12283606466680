import React from "react";
import "../TopRead/TopRead.scss";
import ScrollAnimation from "react-animate-on-scroll";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import ReadItem from "./ReadItem/ReadItem";
import news from "../../../../../assets/images/newsletter.svg";
import NewslatterForm from "../../../../newslatter-form/newslatter-form";
import Translate from "react-translate-component";
import axios from "axios";

class TopRead extends React.Component {
  _isMounted = false;

  state = {
    blogs: []
  };

  componentDidMount() {
    this._isMounted = true;
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/rest/blogs`)
      .then(res => {
        if (this._isMounted) {
          const blogs = res.data.items;
          this.setState({ blogs });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <section className="TopRead">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <SectionHeadline className="dark small left">
                  <Translate content="blog.read_headline" />
                </SectionHeadline>
              </ScrollAnimation>
            </div>
            <div className="col-md-12 col-lg-7">
              <div className="row">
                {this.state.blogs.slice(0, 4).map(item => {
                  return (
                    <ReadItem
                      key={item.id}
                      id={item.id}
                      externalUrl={item.externalUrl}
                      headline={item.title}
                      text={item.description}
                      img={item.headingImageURL}
                      date={item.publishedAt}
                    />
                  );
                })}
              </div>
            </div>
            <div className="col-md-12 offset-lg-1 col-lg-4 offset-xl-2 col-xl-3 mt-3">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="cs_newsletter">
                  <Translate
                    content="homepage.newsletter.headline"
                    className="cs_newsletter_head"
                    component="h3"
                  />
                  <NewslatterForm className="newslatter-blog" />
                  <img src={news} alt="envelope" />
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TopRead;
