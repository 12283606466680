import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "../AboutItem/AboutItem.scss";

const AboutItem = (props) => {
  return (
    <div className="AboutItem col-md-4 mt-5 text-center">
      <ScrollAnimation
        animateIn="fadeInLeft"
        animateOnce={true}
        delay={props.delay}
      >
        <div className="cs_imgwrap">
          <img src={props.img} alt="about-icon" />
        </div>
        <p className="cs_title">{props.headline}</p>
        <p className="cs_text">{props.text}</p>
      </ScrollAnimation>
    </div>
  );
};

export default AboutItem;
