import React from "react";
import ReactPlayer from "react-player";
import "../AboutVideo/AboutVideo.scss";
import ScrollAnimation from "react-animate-on-scroll";
import CSModal from "../../../../../UI/Modal/Modal";

class AboutVideo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false
    };
  }

  modalClose = () => this.setState({ modalShow: false });

  render() {
    return (
      <div className="AboutVideo col-lg-4 mt-5 text-center">
        <CSModal
          show={this.state.modalShow}
          onHide={this.modalClose}
          className="cs-video-modal"
        >
          <ReactPlayer url={this.props.url} width="100%" controls />
        </CSModal>
        <ScrollAnimation
          animateIn="fadeInLeft"
          animateOnce={true}
          delay={this.props.delay}
        >
          <div className="cs_videowrap">
            <img src={this.props.img} alt="about-icon" />
            <div
              onClick={() => this.setState({ modalShow: true })}
              className="player-button"
            ></div>
          </div>
          <p className="cs_title">{this.props.headline}</p>
          <p className="cs_text">{this.props.text}</p>
        </ScrollAnimation>
      </div>
    );
  }
}

export default AboutVideo;
