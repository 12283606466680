import React, { useState, useEffect } from "react";
import logo from "../../assets/images/Logo-w-slogan.svg";
import Navigation from "../Navigation/Navigation";
import { HashLink as Link } from "react-router-hash-link";
import open from "../../assets/images/menu.svg";
import close from "../../assets/images/close.svg";
import "../Header/Header.scss";
import useWindowSize from "../../utils/screen-size";

const Header = () => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width > 991;
  const [isOpened, setOpened] = useState(false);

  useEffect(() => {
    if (isOpened) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpened]);

  useEffect(() => {
    if (isMobile) {
      setOpened(false);
      console.log(isMobile);
    }
  }, [isMobile]);

  const closeMenu = () => {
    setOpened(false);
  };

  return (
    <header className="Header">
      <div className="container">
        <div className="row">
          <div className="col-2">
            <Link smooth to="/#top" onClick={() => closeMenu()}>
              <img src={logo} alt="caresee-logo" />
            </Link>
          </div>
          <div className="col-10 text-right">
            <Navigation className="bordered dark thin-border is-bigger is-small" />
            <button
              className="cs_menu_trigger"
              onClick={() => setOpened(!isOpened)}
            >
              {isOpened ? (
                <img src={close} alt="menu-close" />
              ) : (
                <img src={open} alt="menu-open" />
              )}
            </button>
          </div>
        </div>
      </div>
      {isOpened ? (
        <div className="cs_mobile_menu active">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Navigation
                  onClick={() => closeMenu()}
                  className="bordered dark thin-border is-bigger full-width mobile-menu"
                  btnQuestionnaire="full-width btn-questionnaire"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
