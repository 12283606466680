import React from 'react';
import '../SectionHeadline/SectionHeadline.scss';

const SectionHeadline = (props) => {
    return(
        <h2 className={`SectionHeadline ${props.className}`}>{props.children}</h2>
    );
}


export default SectionHeadline;