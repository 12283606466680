import React from "react";
import pin from "../../../../assets/images/pin.svg";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={17}
      defaultCenter={{ lat: 48.809343, lng: 17.161965 }}
    >
      {props.isMarkerShown && (
        <Marker icon={pin} position={{ lat: 48.809343, lng: 17.161965 }} />
      )}
    </GoogleMap>
  ))
);

export default Map;
